"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var base_1 = require("../../store/base");
var router_1 = require("../../router");
var BASE_URL = 'https://romanrepairs.online/api';
var apiClient = axios_1.default.create({
    baseURL: "".concat(BASE_URL, "/portal"),
    headers: {
        'Content-Type': 'application/json',
        'Portal': 'true',
    }
});
apiClient.interceptors.request.use(function (config) {
    var token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = "Bearer ".concat(token);
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
apiClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        base_1.store.dispatch('logout');
        router_1.default.push("/login");
    }
    return Promise.reject(error);
});
exports.default = apiClient;
