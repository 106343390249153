"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = void 0;
var vuex_1 = require("vuex");
var authApi_1 = require("../../api/authApi");
var ticketStore_1 = require("../ticketStore");
var router_1 = require("../../router");
exports.store = (0, vuex_1.createStore)({
    state: {
        user: null,
        isAuthenticated: false
    },
    getters: {
        getUser: function (state) { return state.user; },
        isAuthenticated: function (state) { return state.isAuthenticated; },
    },
    mutations: {
        SET_USER: function (state, user) {
            state.user = user;
            if (user) {
                state.isAuthenticated = true;
            }
        },
    },
    actions: {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        sendCodeRequest: function (_a, contact_1) {
            return __awaiter(this, arguments, void 0, function (_b, contact) {
                var response, error_1;
                var commit = _b.commit;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, authApi_1.default.sendVerificationCode(contact)];
                        case 1:
                            response = _c.sent();
                            return [2 /*return*/, response.data];
                        case 2:
                            error_1 = _c.sent();
                            console.error(error_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        authorizeAndStoreUser: function (_a, _b) {
            return __awaiter(this, arguments, void 0, function (_c, _d) {
                var response, error_2;
                var commit = _c.commit;
                var contact = _d.contact, code = _d.code;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            _e.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, authApi_1.default.verifyCode(contact, code)];
                        case 1:
                            response = _e.sent();
                            localStorage.setItem('token', response.data.token);
                            commit('SET_USER', response.data);
                            return [4 /*yield*/, router_1.default.push("/")];
                        case 2:
                            _e.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            error_2 = _e.sent();
                            console.error(error_2);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        logout: function (_a) {
            var commit = _a.commit;
            localStorage.removeItem('token');
            commit('SET_USER', null);
        }
    },
    modules: {
        ticketStore: ticketStore_1.default,
    }
});
