<template>
  <v-footer class="mt-6">
    <v-card flat width="100%" class="text-center">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <h3 class="text-h6 mb-2">Contact Us</h3>
            <div class="text-body-2">
              <div><v-icon icon="mdi-map-marker" size="small"></v-icon> 123 Repair Street</div>
              <div>Tech City, TC 12345</div>
              <div><v-icon icon="mdi-phone" size="small"></v-icon> +1 (555) 123-4567</div>
              <div><v-icon icon="mdi-email" size="small"></v-icon> support@repairtech.com</div>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <h3 class="text-h6 mb-2">Business Hours</h3>
            <div class="text-body-2">
              <div>Monday - Friday: 9:00 - 18:00</div>
              <div>Saturday: 10:00 - 16:00</div>
              <div>Sunday: Closed</div>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <h3 class="text-h6 mb-2">Quick Links</h3>
            <div class="text-body-2">
              <div><a href="#" class="text-decoration-none">About Us</a></div>
              <div><a href="#" class="text-decoration-none">Services</a></div>
              <div><a href="#" class="text-decoration-none">Privacy Policy</a></div>
              <div><a href="#" class="text-decoration-none">Terms of Service</a></div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <div class="text-center text-body-2">
          © {{ new Date().getFullYear() }} RepairTech. All rights reserved.
        </div>
        <div class="text-center text-caption text-medium-emphasis">
          Trusted by thousands of customers worldwide
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script setup lang="ts">
</script>

<style scoped>
.v-footer {
  background-color: #f5f5f5;
}

a {
  color: var(--v-theme-primary);
}

a:hover {
  color: var(--v-theme-secondary);
}
</style>