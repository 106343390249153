"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    key: 0,
    class: "logout-button-container"
};
var _hoisted_2 = { class: "contact-item d-flex align-center mb-4" };
var _hoisted_3 = { class: "contact-item d-flex align-center" };
var vue_3 = require("vue");
var vue_router_1 = require("vue-router");
var base_1 = require("../store/base");
exports.default = (0, vue_1.defineComponent)({
    __name: 'PortalHeader',
    setup: function (__props) {
        var router = (0, vue_router_1.useRouter)();
        var isHovered = (0, vue_3.ref)(false);
        var token = (0, vue_3.computed)(function () { return base_1.store.getters.isAuthenticated; });
        var handleLogout = function () {
            base_1.store.dispatch('logout');
            router.push('/login');
        };
        return function (_ctx, _cache) {
            var _component_v_col = (0, vue_2.resolveComponent)("v-col");
            var _component_v_icon = (0, vue_2.resolveComponent)("v-icon");
            var _component_v_btn = (0, vue_2.resolveComponent)("v-btn");
            var _component_v_card_text = (0, vue_2.resolveComponent)("v-card-text");
            var _component_v_card = (0, vue_2.resolveComponent)("v-card");
            var _component_v_row = (0, vue_2.resolveComponent)("v-row");
            var _component_v_container = (0, vue_2.resolveComponent)("v-container");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_card, {
                class: "portal-header mb-6",
                elevation: "0"
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)(_component_v_container, null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_v_row, {
                                align: "center",
                                class: "header-content"
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)(_component_v_col, {
                                        cols: "12",
                                        md: "7",
                                        class: "header-text"
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return _cache[2] || (_cache[2] = [
                                            (0, vue_2.createElementVNode)("div", { class: "welcome-section" }, [
                                                (0, vue_2.createElementVNode)("h1", { class: "text-h3 font-weight-bold mb-2 welcome-title" }, [
                                                    (0, vue_2.createTextVNode)(" Welcome to RepairTech "),
                                                    (0, vue_2.createElementVNode)("span", { class: "gradient-text" }, "Service Portal")
                                                ]),
                                                (0, vue_2.createElementVNode)("p", { class: "text-body-1 mb-4 welcome-description" }, " Track your device repairs and communicate with our technicians in real-time. We're here to help you every step of the way. ")
                                            ], -1)
                                        ]); }),
                                        _: 1
                                    }),
                                    (0, vue_2.createVNode)(_component_v_col, {
                                        cols: "12",
                                        md: "5",
                                        class: "contact-section"
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)(_component_v_card, {
                                                class: "contact-card",
                                                elevation: "3"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (token.value)
                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                                                            (0, vue_2.createVNode)(_component_v_btn, {
                                                                color: "red",
                                                                variant: "outlined",
                                                                class: "logout-button",
                                                                onMouseenter: _cache[0] || (_cache[0] = function ($event) { return (isHovered.value = true); }),
                                                                onMouseleave: _cache[1] || (_cache[1] = function ($event) { return (isHovered.value = false); }),
                                                                onClick: handleLogout
                                                            }, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createVNode)(_component_v_icon, {
                                                                        icon: "mdi-logout",
                                                                        class: (0, vue_2.normalizeClass)(["login-icon", { 'rotate-icon': isHovered.value }])
                                                                    }, null, 8, ["class"]),
                                                                    (0, vue_2.createElementVNode)("span", {
                                                                        class: (0, vue_2.normalizeClass)(["logout-text", { 'text-white': isHovered.value }])
                                                                    }, " Logout ", 2)
                                                                ]; }),
                                                                _: 1
                                                            })
                                                        ]))
                                                        : (0, vue_2.createCommentVNode)("", true),
                                                    (0, vue_2.createVNode)(_component_v_card_text, null, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createElementVNode)("div", _hoisted_2, [
                                                                (0, vue_2.createVNode)(_component_v_icon, {
                                                                    icon: "mdi-clock-outline",
                                                                    size: "large",
                                                                    color: "primary",
                                                                    class: "me-3"
                                                                }),
                                                                _cache[3] || (_cache[3] = (0, vue_2.createElementVNode)("div", null, [
                                                                    (0, vue_2.createElementVNode)("div", { class: "text-subtitle-1 font-weight-medium" }, "Business Hours"),
                                                                    (0, vue_2.createElementVNode)("div", { class: "text-body-2 text-medium-emphasis" }, "Mon-Fri 9:00-18:00")
                                                                ], -1))
                                                            ]),
                                                            (0, vue_2.createElementVNode)("div", _hoisted_3, [
                                                                (0, vue_2.createVNode)(_component_v_icon, {
                                                                    icon: "mdi-phone",
                                                                    size: "large",
                                                                    color: "primary",
                                                                    class: "me-3"
                                                                }),
                                                                _cache[4] || (_cache[4] = (0, vue_2.createElementVNode)("div", null, [
                                                                    (0, vue_2.createElementVNode)("div", { class: "text-subtitle-1 font-weight-medium" }, "Emergency Support"),
                                                                    (0, vue_2.createElementVNode)("a", {
                                                                        href: "tel:+15551234567",
                                                                        class: "text-body-2 text-primary text-decoration-none"
                                                                    }, " +1 (555) 123-4567 ")
                                                                ], -1))
                                                            ])
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
