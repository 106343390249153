"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var date_fns_1 = require("date-fns");
exports.default = (0, vue_1.defineComponent)({
    name: 'TicketDetails',
    props: {
        ticket: {
            type: Object,
            required: true
        }
    },
    computed: {
        formatStatus: function () {
            return this.ticket.ticketStatus.replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, function (l) { return l.toUpperCase(); });
        }
    },
    methods: {
        formatDate: function (date) {
            return (0, date_fns_1.format)(new Date(date), 'PPP');
        }
    }
});
