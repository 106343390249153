"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("vue-router");
var LoginPage_vue_1 = require("../components/LoginPage.vue");
var TicketList_vue_1 = require("../components/TicketList.vue");
var routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginPage_vue_1.default
    },
    {
        path: '/',
        name: 'home',
        component: TicketList_vue_1.default,
        meta: { requiresAuth: true }
    }
];
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)(),
    routes: routes
});
/*router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated) {
            next('/login')
        } else {
            next()
        }
    } else {
        next()
    }
})*/
exports.default = router;
