"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "ticket-list" };
var _hoisted_2 = { class: "d-flex align-center justify-space-between w-100" };
var _hoisted_3 = { class: "status-text" };
var vue_3 = require("vue");
var TicketDetails_vue_1 = require("./TicketDetails.vue");
var MessageList_vue_1 = require("./MessageList.vue");
var vuex_1 = require("vuex");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TicketList',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var tickets = (0, vue_3.computed)(function () { return store.state.ticketStore.tickets; });
        var isLightColor = function (ticket) {
            var _a;
            var color = ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.ticketStatus) === null || _a === void 0 ? void 0 : _a.color) || '';
            var hex = color.replace('#', '');
            var r = parseInt(hex.substr(0, 2), 16);
            var g = parseInt(hex.substr(2, 2), 16);
            var b = parseInt(hex.substr(4, 2), 16);
            var brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 128;
        };
        return function (_ctx, _cache) {
            var _component_v_chip = (0, vue_2.resolveComponent)("v-chip");
            var _component_v_expansion_panel_title = (0, vue_2.resolveComponent)("v-expansion-panel-title");
            var _component_v_expansion_panel_text = (0, vue_2.resolveComponent)("v-expansion-panel-text");
            var _component_v_expansion_panel = (0, vue_2.resolveComponent)("v-expansion-panel");
            var _component_v_expansion_panels = (0, vue_2.resolveComponent)("v-expansion-panels");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_v_expansion_panels, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(tickets.value, function (ticket) {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_expansion_panel, {
                                key: ticket.id,
                                value: ticket.id
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)(_component_v_expansion_panel_title, null, {
                                        default: (0, vue_2.withCtx)(function () {
                                            var _a, _b;
                                            return [
                                                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                                                    (0, vue_2.createElementVNode)("span", null, "Ticket #" + (0, vue_2.toDisplayString)(ticket.ticketNumber), 1),
                                                    (0, vue_2.createVNode)(_component_v_chip, {
                                                        class: (0, vue_2.normalizeClass)(["status-chip ml-2", { 'status-chip--light': isLightColor(ticket) }]),
                                                        style: (0, vue_2.normalizeStyle)({ '--status-color': (_a = ticket === null || ticket === void 0 ? void 0 : ticket.ticketStatus) === null || _a === void 0 ? void 0 : _a.color, '--text-color': (_b = ticket === null || ticket === void 0 ? void 0 : ticket.ticketStatus) === null || _b === void 0 ? void 0 : _b.textColor })
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () {
                                                            var _a;
                                                            return [
                                                                (0, vue_2.createElementVNode)("span", _hoisted_3, (0, vue_2.toDisplayString)((_a = ticket === null || ticket === void 0 ? void 0 : ticket.ticketStatus) === null || _a === void 0 ? void 0 : _a.name), 1)
                                                            ];
                                                        }),
                                                        _: 2
                                                    }, 1032, ["class", "style"])
                                                ])
                                            ];
                                        }),
                                        _: 2
                                    }, 1024),
                                    (0, vue_2.createVNode)(_component_v_expansion_panel_text, null, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)(TicketDetails_vue_1.default, { ticket: ticket }, null, 8, ["ticket"]),
                                            (0, vue_2.createVNode)(MessageList_vue_1.default, {
                                                ticketId: ticket.id
                                            }, null, 8, ["ticketId"])
                                        ]; }),
                                        _: 2
                                    }, 1024)
                                ]; }),
                                _: 2
                            }, 1032, ["value"]));
                        }), 128))
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
