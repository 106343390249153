"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "message-list" };
var _hoisted_2 = { class: "message-content" };
var _hoisted_3 = { class: "message-header" };
var _hoisted_4 = { class: "sender-name" };
var _hoisted_5 = { class: "message-time" };
var vue_3 = require("vue");
var date_fns_1 = require("date-fns");
var ticketApi_1 = require("../api/ticketApi");
exports.default = (0, vue_1.defineComponent)({
    __name: 'MessageList',
    props: {
        ticketId: {}
    },
    setup: function (__props) {
        var _this = this;
        var props = __props;
        var messages = (0, vue_3.ref)([]);
        var newMessage = (0, vue_3.ref)('');
        var messagesContainer = (0, vue_3.ref)(null);
        var loading = (0, vue_3.ref)(false);
        var sending = (0, vue_3.ref)(false);
        var sortedMessages = (0, vue_3.computed)(function () {
            return __spreadArray([], messages.value, true).sort(function (a, b) {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            });
        });
        var formatMessageTime = function (date) {
            return (0, date_fns_1.format)(new Date(date), 'PPp');
        };
        var scrollToBottom = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) {
                            requestAnimationFrame(function () {
                                requestAnimationFrame(function () { return __awaiter(_this, void 0, void 0, function () {
                                    var scrollHeight;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, (0, vue_3.nextTick)()];
                                            case 1:
                                                _a.sent();
                                                if (messagesContainer.value) {
                                                    scrollHeight = messagesContainer.value.scrollHeight;
                                                    messagesContainer.value.scrollTop = scrollHeight;
                                                }
                                                resolve(true);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); });
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var loadMessages = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!props.ticketId)
                            return [2 /*return*/];
                        loading.value = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, ticketApi_1.default.getMessages(props.ticketId)];
                    case 2:
                        response = _a.sent();
                        messages.value = response.data;
                        setTimeout(function () {
                            scrollToBottom();
                        }, 100);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Failed to load messages:', error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var sendMessage = function () { return __awaiter(_this, void 0, void 0, function () {
            var messageContent, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!newMessage.value.trim() || sending.value || !props.ticketId)
                            return [2 /*return*/];
                        sending.value = true;
                        messageContent = newMessage.value.trim();
                        newMessage.value = '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, ticketApi_1.default.sendMessage(props.ticketId, messageContent)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, loadMessages()];
                    case 3:
                        _a.sent();
                        setTimeout(function () {
                            scrollToBottom();
                        }, 150);
                        return [3 /*break*/, 6];
                    case 4:
                        error_2 = _a.sent();
                        console.error('Failed to send message:', error_2);
                        newMessage.value = messageContent;
                        return [3 /*break*/, 6];
                    case 5:
                        sending.value = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        var getSenderFormattedName = function (message) {
            if (message.senderFirstName && message.senderLastName) {
                return message.senderFirstName + ' ' + message.senderLastName;
            }
            else {
                return message.senderUserName;
            }
        };
        (0, vue_3.onMounted)(function () {
            loadMessages();
        });
        (0, vue_3.onUpdated)(function () {
            (0, vue_3.nextTick)(function () {
                scrollToBottom();
            });
        });
        (0, vue_3.watch)(messages, function () {
            (0, vue_3.nextTick)(function () {
                scrollToBottom();
            });
        }, { deep: true });
        return function (_ctx, _cache) {
            var _component_v_card_title = (0, vue_2.resolveComponent)("v-card-title");
            var _component_v_progress_circular = (0, vue_2.resolveComponent)("v-progress-circular");
            var _component_v_alert = (0, vue_2.resolveComponent)("v-alert");
            var _component_v_textarea = (0, vue_2.resolveComponent)("v-textarea");
            var _component_v_btn = (0, vue_2.resolveComponent)("v-btn");
            var _component_v_form = (0, vue_2.resolveComponent)("v-form");
            var _component_v_card_text = (0, vue_2.resolveComponent)("v-card-text");
            var _component_v_card = (0, vue_2.resolveComponent)("v-card");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_v_card, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_v_card_title, null, {
                            default: (0, vue_2.withCtx)(function () { return _cache[1] || (_cache[1] = [
                                (0, vue_2.createTextVNode)("Messages")
                            ]); }),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_v_card_text, null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createElementVNode)("div", {
                                    class: "messages-container",
                                    ref_key: "messagesContainer",
                                    ref: messagesContainer
                                }, [
                                    (loading.value)
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_progress_circular, {
                                            key: 0,
                                            indeterminate: "",
                                            color: "primary",
                                            class: "loader"
                                        }))
                                        : (messages.value && messages.value.length > 0)
                                            ? ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 1 }, (0, vue_2.renderList)(sortedMessages.value, function (message) {
                                                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                                    key: message.id,
                                                    class: (0, vue_2.normalizeClass)(['message', message.senderType.toLowerCase()])
                                                }, [
                                                    (0, vue_2.createElementVNode)("div", _hoisted_2, [
                                                        (0, vue_2.createElementVNode)("div", _hoisted_3, [
                                                            (0, vue_2.createElementVNode)("span", _hoisted_4, (0, vue_2.toDisplayString)(getSenderFormattedName(message)), 1),
                                                            (0, vue_2.createElementVNode)("span", _hoisted_5, (0, vue_2.toDisplayString)(formatMessageTime(message.createdAt)), 1)
                                                        ]),
                                                        (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(message.content), 1)
                                                    ])
                                                ], 2));
                                            }), 128))
                                            : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_alert, {
                                                key: 2,
                                                type: "info",
                                                text: "No messages yet",
                                                class: "ma-4"
                                            }))
                                ], 512),
                                (0, vue_2.createVNode)(_component_v_form, {
                                    onSubmit: (0, vue_2.withModifiers)(sendMessage, ["prevent"]),
                                    class: "message-form"
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_v_textarea, {
                                            modelValue: newMessage.value,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((newMessage).value = $event); }),
                                            label: "Type your message",
                                            rows: "3",
                                            "auto-grow": "",
                                            "hide-details": "",
                                            class: "message-input",
                                            disabled: sending.value
                                        }, null, 8, ["modelValue", "disabled"]),
                                        (0, vue_2.createVNode)(_component_v_btn, {
                                            color: "primary",
                                            type: "submit",
                                            disabled: !newMessage.value.trim() || sending.value,
                                            loading: sending.value,
                                            class: "mt-2"
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return _cache[2] || (_cache[2] = [
                                                (0, vue_2.createTextVNode)(" Send Message ")
                                            ]); }),
                                            _: 1
                                        }, 8, ["disabled", "loading"])
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
